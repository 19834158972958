import React, { FC, useState } from 'react';
import { Container } from 'react-bootstrap';
import SuggestionForm from '../../ui/SuggestionForm/SuggestionForm';
import { Alert } from 'react-bootstrap';

import { Props } from '../../../App';

interface SProps extends Props {
  id: number;
}

const Suggestion: FC<SProps> = (props): JSX.Element => {
  const [show, setShow] = useState(true);
  return (
    <section className="top">
      <Container fluid="md">
        {show && (
          <Alert className='text-center' variant='warning' onClose={() => setShow(false)} dismissible>
            Tato služba slouží pouze k hlášení technických poruch, týkajících se fungování systému Změňte.to. V případě nouze volejte 112.
          </Alert>
        )}
        <h1 className="px-3 mb-5 display-3 text-center">{props.header}</h1>
        <SuggestionForm id={props.id} />
      </Container>
    </section>
  );
};

export default Suggestion;
